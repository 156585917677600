<template>
  <div class="swipe-wrapper">
    <div v-if="show" class="popup-mask" @click.stop="maskClick($event)"></div>
    <div class="scroll-container">
      <p v-if="title" class="popup-title">
        {{ title }}<span class="cube-dialog-close" @click.stop="maskClick($event)"><i class="cubeic-close"></i></span>
      </p>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default() {
        return '';
      }
    }
  },
  data() {
    return {
      show: false
    };
  },
  mounted() {
    this.showPopupFn();
  },
  methods: {
    maskClick(event) {
      event.stopPropagation();
      event.preventDefault();
      this.$emit('closeFn', false);
      this.show = false;
    },
    showPopupFn() {
      this.$store.dispatch('setDialogStatus', true);
      let self = this;
      setTimeout(() => {
        self.show = true;
      }, 100);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/styles/var.scss';
.swipe-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background: #fff;
  z-index: 99;
  .popup-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(37,38,45, 0.4);
    pointer-events: auto;
  }
  .scroll-container {
    min-width: 300px;
    background-color: #fff;
    border-radius: 8px;
    padding-bottom: 10px;
    @include position-center();
  }
  .popup-title {
    font-size: 14px;
    padding: 15px;
    border-bottom: 1px solid #f0f0f4;
  }
  .cube-dialog-close {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    color: #999;
    font-size: 18px;
  }
}
</style>
