<template>
  <div class="group-header" style="justify-content: space-between">
    <div
      v-if="showHeader"
      style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      "
    >
      <div style="display: flex">
        <span class="icon">
          <img
            v-if="iconUrl"
            :src="require('./../assets' + iconUrl)"
            alt=""
            width="22"
            :style="
              iconUrl.indexOf('strategy') > -1
                ? 'margin-top: 10px'
                : 'margin-top: 4px'
            "
          />
          <font-awesome-icon
            v-else
            class="dimension-icon"
            :icon="['fa', icon ? icon : 'user']"
          />
        </span>
        <p class="title">
          <span>{{ title }}</span>
          <slot name="title"></slot>
        </p>
      </div>

      <van-button
        v-if="isRight"
        class="change-button"
        round
        type="info"
        size="mini"
        :color="isEdit ? '#A6A6A6' : ''"
        @click="
          () => {
            isEdit = !isEdit;
            $emit('edit');
          }
        "
      >
        <span v-if="!isEdit">修改</span>
        <span v-else>取消</span>
      </van-button>
    </div>
    <div style="display: flex; justify-content: center">
      <slot name="before"></slot>
      <Feedback
        v-if="false && !noFeedback"
        :module-name="title"
        :need-dialog-status="needDialogStatus"
        :data-source="source"
      />
      <slot name="after"></slot>
    </div>
  </div>
</template>
<script>
import Feedback from '@/components/Feedback';
import Button from 'vant/lib/button';
export default {
  components: {
    Feedback,
    vanButton: Button,
  },
  props: {
    title: {
      type: String,
      default() {
        return '';
      },
    },
    icon: {
      type: String,
      default() {
        return '';
      },
    },
    source: {
      type: String,
      default() {
        return '';
      },
    },
    noFeedback: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showHeader: {
      type: Boolean,
      default() {
        return true;
      },
    },
    needDialogStatus: {
      type: Boolean,
      default() {
        return true;
      },
    },
    iconUrl: {
      type: String,
      default() {
        return '';
      },
    },
    isRight: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      isEdit: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.change-button {
  width: 45px;
  height: 22px;
}
</style>
