<template>
  <div v-if="summaryData" class="summary-container">
    <div class="summary-item">
      <span class="label">{{ $t('publication_title') }}</span>
      <span
        class="value publication"
      ><label class="v-bd" @click="openTypeFn(0)">{{
        summaryData[0]
      }}</label></span>
    </div>
    <div class="summary-item">
      <span class="label">{{ $t('professional') }}</span>
      <span
        class="value clinical-trial"
      ><label class="v-bd" @click="openTypeFn(1)">{{
        summaryData[1]
      }}</label></span>
    </div>
    <div class="summary-item">
      <span class="label">{{ $t('project') }}</span>
      <span
        class="value project"
      ><label class="v-bd" @click="openTypeFn(2)">{{
        summaryData[2]
      }}</label></span>
    </div>
    <div class="summary-item">
      <span class="label">{{ $t('award') }}</span>
      <span
        class="value"
      ><label class="v-bd" @click="openTypeFn(3)">{{
        summaryData[3]
      }}</label></span>
    </div>
    <div class="summary-item">
      <span class="label">指南共识</span>
      <span
        class="value"
      ><label class="v-bd" @click="openTypeFn(4)">{{
        summaryData[4]
      }}</label></span>
    </div>
    <div class="summary-item">
      <span class="label">专利发明</span>
      <span
        class="value"
      ><label class="v-bd" @click="openTypeFn(5)">{{
        summaryData[5]
      }}</label></span>
    </div>
  </div>
</template>

<script>
import {debounce, LightenDarkenColor} from '@/utils';
import {colorlist} from '@/utils/defaultConstant';
export default {
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
      summaryData: null,
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val);
      },
    },
  },
  mounted() {
    this.initChart();
    if (this.autoResize) {
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize();
        }
      }, 100);
      window.addEventListener('resize', this.__resizeHandler);
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    if (this.autoResize) {
      window.removeEventListener('resize', this.__resizeHandler);
    }

    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    openTypeFn(type) {
      this.$store.dispatch('setLearningType', type);
      this.$emit('searchFn', type);
    },
    sidebarResizeHandler(e) {
      if (e.propertyName === 'width') {
        this.__resizeHandler();
      }
    },
    setOptions(data) {
      if (
        data &&
        data.seriesData &&
        data.seriesData.length > 0 &&
        data.seriesData[0].data &&
        data.seriesData[0].data.length
      ) {
        this.summaryData = data.seriesData[0].data;
      }
    },
    initChart() {
      this.setOptions(this.chartData);
    },
  },
};
</script>
<style scoped lang="scss">
.summary-container {
  display: flex;
  align-items: center;
  justify-content: space-around;

  .summary-item {
    flex: 1;
    padding: 15px 0;
    .label {
      font-size: 10px;
      color: #666;
      display: block;
    }
    .value {
      padding: 10px;
      display: block;
      font-size: 28px;

      color: #3caa45;
      .v-bd {
        border-bottom: 1px solid #3caa45;
        font-weight: initial;
      }
      // &.publication {
      //     color: #3caa45;
      // }
      // &.clinical-trial {
      //     color: #1362ad;
      // }
      // &.project {
      //     color: #7EC0EE;
      // }
      // &.award {
      //     color: #FFD700;
      // }
    }
  }
}
</style>
