
import {
  getFirstUpperChar,
  getLocalStorage,
  setLocalStorage,
  debounce,
} from "@/utils/index";
import * as help from "@/api/help";

export default {
  methods: {
    nextFn(res) {
      if (this.pageName === 'doctorList') {
        this.initPage(res);
      }
      if (this.pageName === 'doctorDetail') {
        this.getTerritorieInfo(res);
      }
    },
    getUserId() {
      let userIdKey = this.$route.query.user_id || getLocalStorage("user_id_key");

      // 判断有没有加密后的user_id
      if (userIdKey && isNaN(+userIdKey)) {
        // 如果跟之前保存的一样就取之前的保存user_id
        if (userIdKey === getLocalStorage("user_id_key")) {
          if (getLocalStorage("user_id")) {
            this.nextFn(getLocalStorage("user_id"));
          } else {
            // 之前的值不存在的就通过key重新取,每重新取一次就更新一下职位
            help
              .descrypt(userIdKey)
              .then((res) => {
                if (res) {
                  setLocalStorage("user_id", res);
                  // 判断页面
                  this.nextFn(res);
                } else {
                  this.$router.push({
                    path: "/error",
                    query: {},
                  });
                }
              })
              .catch((err) => {
                console.log(err);
                this.$router.push({
                  path: "/error",
                  query: {},
                });
              });
          }
        } else {
          // 更新user_id_key
          setLocalStorage("user_id_key", userIdKey);
          // 通过key重新取user_id,每重新取一次就更新一下职位
          help
            .descrypt(userIdKey)
            .then((res) => {
              if (res) {
                setLocalStorage("user_id", res);
                this.nextFn(res);
              } else {
                this.$router.push({
                  path: "/error",
                  query: {},
                });
              }
            })
            .catch((err) => {
              console.log(err);
              this.$router.push({
                path: "/error",
                query: {},
              });
            });
        }
      } else {
        this.$router.push({
          path: "/error",
          query: {},
        });
      }
    },
  }
};
